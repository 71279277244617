import React from 'react';
import '../App.css';
import logo from '../../src/assets/img/Original.png';

{/* Apps page */}

function Home() {
    return (
        <header className="App-header2">
          <img src={logo} className="App-logo" alt="logo" />
      </header>
    );
}

export default Home;