import React from 'react';

import '../App.css';

{/* Privacy page */}

function Sent() {
    return (
      <div>
            <div className="center">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h1>Thank you for contacting us</h1>
                <h2>We will reach out to you as soon as possible!</h2>
            </div>
      </div>
    );
}

export default Sent;